exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-reviews-js": () => import("./../../../src/pages/about/reviews.js" /* webpackChunkName: "component---src-pages-about-reviews-js" */),
  "component---src-pages-about-vacancy-js": () => import("./../../../src/pages/about/vacancy.js" /* webpackChunkName: "component---src-pages-about-vacancy-js" */),
  "component---src-pages-articles-architectura-js": () => import("./../../../src/pages/articles/architectura.js" /* webpackChunkName: "component---src-pages-articles-architectura-js" */),
  "component---src-pages-articles-beliy-interier-js": () => import("./../../../src/pages/articles/beliy-interier.js" /* webpackChunkName: "component---src-pages-articles-beliy-interier-js" */),
  "component---src-pages-articles-clients-js": () => import("./../../../src/pages/articles/clients.js" /* webpackChunkName: "component---src-pages-articles-clients-js" */),
  "component---src-pages-articles-dveri-js": () => import("./../../../src/pages/articles/dveri.js" /* webpackChunkName: "component---src-pages-articles-dveri-js" */),
  "component---src-pages-articles-hranenie-js": () => import("./../../../src/pages/articles/hranenie.js" /* webpackChunkName: "component---src-pages-articles-hranenie-js" */),
  "component---src-pages-articles-index-js": () => import("./../../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-articles-krovlya-js": () => import("./../../../src/pages/articles/krovlya.js" /* webpackChunkName: "component---src-pages-articles-krovlya-js" */),
  "component---src-pages-articles-lighting-js": () => import("./../../../src/pages/articles/lighting.js" /* webpackChunkName: "component---src-pages-articles-lighting-js" */),
  "component---src-pages-articles-obschaya-zona-js": () => import("./../../../src/pages/articles/obschaya-zona.js" /* webpackChunkName: "component---src-pages-articles-obschaya-zona-js" */),
  "component---src-pages-articles-plan-apart-js": () => import("./../../../src/pages/articles/plan-apart.js" /* webpackChunkName: "component---src-pages-articles-plan-apart-js" */),
  "component---src-pages-articles-plan-house-js": () => import("./../../../src/pages/articles/plan-house.js" /* webpackChunkName: "component---src-pages-articles-plan-house-js" */),
  "component---src-pages-articles-reconstruction-js": () => import("./../../../src/pages/articles/reconstruction.js" /* webpackChunkName: "component---src-pages-articles-reconstruction-js" */),
  "component---src-pages-articles-spalnya-js": () => import("./../../../src/pages/articles/spalnya.js" /* webpackChunkName: "component---src-pages-articles-spalnya-js" */),
  "component---src-pages-articles-trends-js": () => import("./../../../src/pages/articles/trends.js" /* webpackChunkName: "component---src-pages-articles-trends-js" */),
  "component---src-pages-calculator-js": () => import("./../../../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-cost-js": () => import("./../../../src/pages/cost.js" /* webpackChunkName: "component---src-pages-cost-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-apartment-js": () => import("./../../../src/pages/portfolio/apartment.js" /* webpackChunkName: "component---src-pages-portfolio-apartment-js" */),
  "component---src-pages-portfolio-architecture-dom-na-holme-js": () => import("./../../../src/pages/portfolio/architecture/dom-na-holme.js" /* webpackChunkName: "component---src-pages-portfolio-architecture-dom-na-holme-js" */),
  "component---src-pages-portfolio-architecture-dom-u-more-js": () => import("./../../../src/pages/portfolio/architecture/dom-u-more.js" /* webpackChunkName: "component---src-pages-portfolio-architecture-dom-u-more-js" */),
  "component---src-pages-portfolio-architecture-exterier-baltic-house-js": () => import("./../../../src/pages/portfolio/architecture/exterier-baltic-house.js" /* webpackChunkName: "component---src-pages-portfolio-architecture-exterier-baltic-house-js" */),
  "component---src-pages-portfolio-architecture-garmony-dom-arch-js": () => import("./../../../src/pages/portfolio/architecture/garmony-dom-arch.js" /* webpackChunkName: "component---src-pages-portfolio-architecture-garmony-dom-arch-js" */),
  "component---src-pages-portfolio-architecture-index-js": () => import("./../../../src/pages/portfolio/architecture/index.js" /* webpackChunkName: "component---src-pages-portfolio-architecture-index-js" */),
  "component---src-pages-portfolio-architecture-libknehta-js": () => import("./../../../src/pages/portfolio/architecture/libknehta.js" /* webpackChunkName: "component---src-pages-portfolio-architecture-libknehta-js" */),
  "component---src-pages-portfolio-architecture-podmoskovye-architecture-js": () => import("./../../../src/pages/portfolio/architecture/podmoskovye-architecture.js" /* webpackChunkName: "component---src-pages-portfolio-architecture-podmoskovye-architecture-js" */),
  "component---src-pages-portfolio-architecture-rekonstrukciya-protos-js": () => import("./../../../src/pages/portfolio/architecture/rekonstrukciya-protos.js" /* webpackChunkName: "component---src-pages-portfolio-architecture-rekonstrukciya-protos-js" */),
  "component---src-pages-portfolio-architecture-shale-js": () => import("./../../../src/pages/portfolio/architecture/shale.js" /* webpackChunkName: "component---src-pages-portfolio-architecture-shale-js" */),
  "component---src-pages-portfolio-architecture-sosni-js": () => import("./../../../src/pages/portfolio/architecture/sosni.js" /* webpackChunkName: "component---src-pages-portfolio-architecture-sosni-js" */),
  "component---src-pages-portfolio-architecture-tarasovo-rekonstrukciya-js": () => import("./../../../src/pages/portfolio/architecture/tarasovo-rekonstrukciya.js" /* webpackChunkName: "component---src-pages-portfolio-architecture-tarasovo-rekonstrukciya-js" */),
  "component---src-pages-portfolio-house-js": () => import("./../../../src/pages/portfolio/house.js" /* webpackChunkName: "component---src-pages-portfolio-house-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-interier-akvamarin-js": () => import("./../../../src/pages/portfolio/interier/akvamarin.js" /* webpackChunkName: "component---src-pages-portfolio-interier-akvamarin-js" */),
  "component---src-pages-portfolio-interier-apart-family-js": () => import("./../../../src/pages/portfolio/interier/apart-family.js" /* webpackChunkName: "component---src-pages-portfolio-interier-apart-family-js" */),
  "component---src-pages-portfolio-interier-apart-for-life-js": () => import("./../../../src/pages/portfolio/interier/apart-for-life.js" /* webpackChunkName: "component---src-pages-portfolio-interier-apart-for-life-js" */),
  "component---src-pages-portfolio-interier-baikonur-js": () => import("./../../../src/pages/portfolio/interier/baikonur.js" /* webpackChunkName: "component---src-pages-portfolio-interier-baikonur-js" */),
  "component---src-pages-portfolio-interier-bedy-js": () => import("./../../../src/pages/portfolio/interier/bedy.js" /* webpackChunkName: "component---src-pages-portfolio-interier-bedy-js" */),
  "component---src-pages-portfolio-interier-billard-bowling-js": () => import("./../../../src/pages/portfolio/interier/billard-bowling.js" /* webpackChunkName: "component---src-pages-portfolio-interier-billard-bowling-js" */),
  "component---src-pages-portfolio-interier-cafe-js": () => import("./../../../src/pages/portfolio/interier/cafe.js" /* webpackChunkName: "component---src-pages-portfolio-interier-cafe-js" */),
  "component---src-pages-portfolio-interier-color-js": () => import("./../../../src/pages/portfolio/interier/color.js" /* webpackChunkName: "component---src-pages-portfolio-interier-color-js" */),
  "component---src-pages-portfolio-interier-compact-js": () => import("./../../../src/pages/portfolio/interier/compact.js" /* webpackChunkName: "component---src-pages-portfolio-interier-compact-js" */),
  "component---src-pages-portfolio-interier-cyprus-js": () => import("./../../../src/pages/portfolio/interier/cyprus.js" /* webpackChunkName: "component---src-pages-portfolio-interier-cyprus-js" */),
  "component---src-pages-portfolio-interier-d-3-js": () => import("./../../../src/pages/portfolio/interier/d3.js" /* webpackChunkName: "component---src-pages-portfolio-interier-d-3-js" */),
  "component---src-pages-portfolio-interier-depo-js": () => import("./../../../src/pages/portfolio/interier/depo.js" /* webpackChunkName: "component---src-pages-portfolio-interier-depo-js" */),
  "component---src-pages-portfolio-interier-design-interier-1-js": () => import("./../../../src/pages/portfolio/interier/design-interier-1.js" /* webpackChunkName: "component---src-pages-portfolio-interier-design-interier-1-js" */),
  "component---src-pages-portfolio-interier-design-interier-2-js": () => import("./../../../src/pages/portfolio/interier/design-interier-2.js" /* webpackChunkName: "component---src-pages-portfolio-interier-design-interier-2-js" */),
  "component---src-pages-portfolio-interier-dom-sovremeniy-js": () => import("./../../../src/pages/portfolio/interier/dom-sovremeniy.js" /* webpackChunkName: "component---src-pages-portfolio-interier-dom-sovremeniy-js" */),
  "component---src-pages-portfolio-interier-filimonova-js": () => import("./../../../src/pages/portfolio/interier/filimonova.js" /* webpackChunkName: "component---src-pages-portfolio-interier-filimonova-js" */),
  "component---src-pages-portfolio-interier-fine-js": () => import("./../../../src/pages/portfolio/interier/fine.js" /* webpackChunkName: "component---src-pages-portfolio-interier-fine-js" */),
  "component---src-pages-portfolio-interier-garmony-dom-interier-js": () => import("./../../../src/pages/portfolio/interier/garmony-dom-interier.js" /* webpackChunkName: "component---src-pages-portfolio-interier-garmony-dom-interier-js" */),
  "component---src-pages-portfolio-interier-gedrojca-js": () => import("./../../../src/pages/portfolio/interier/gedrojca.js" /* webpackChunkName: "component---src-pages-portfolio-interier-gedrojca-js" */),
  "component---src-pages-portfolio-interier-house-minsk-js": () => import("./../../../src/pages/portfolio/interier/house-minsk.js" /* webpackChunkName: "component---src-pages-portfolio-interier-house-minsk-js" */),
  "component---src-pages-portfolio-interier-index-js": () => import("./../../../src/pages/portfolio/interier/index.js" /* webpackChunkName: "component---src-pages-portfolio-interier-index-js" */),
  "component---src-pages-portfolio-interier-interier-baltic-house-js": () => import("./../../../src/pages/portfolio/interier/interier-baltic-house.js" /* webpackChunkName: "component---src-pages-portfolio-interier-interier-baltic-house-js" */),
  "component---src-pages-portfolio-interier-interier-protos-js": () => import("./../../../src/pages/portfolio/interier/interier-protos.js" /* webpackChunkName: "component---src-pages-portfolio-interier-interier-protos-js" */),
  "component---src-pages-portfolio-interier-kaskad-js": () => import("./../../../src/pages/portfolio/interier/kaskad.js" /* webpackChunkName: "component---src-pages-portfolio-interier-kaskad-js" */),
  "component---src-pages-portfolio-interier-korolya-js": () => import("./../../../src/pages/portfolio/interier/korolya.js" /* webpackChunkName: "component---src-pages-portfolio-interier-korolya-js" */),
  "component---src-pages-portfolio-interier-kuzmy-chernogo-js": () => import("./../../../src/pages/portfolio/interier/kuzmy-chernogo.js" /* webpackChunkName: "component---src-pages-portfolio-interier-kuzmy-chernogo-js" */),
  "component---src-pages-portfolio-interier-level-2-nezavisimosti-js": () => import("./../../../src/pages/portfolio/interier/level2-nezavisimosti.js" /* webpackChunkName: "component---src-pages-portfolio-interier-level-2-nezavisimosti-js" */),
  "component---src-pages-portfolio-interier-luxury-js": () => import("./../../../src/pages/portfolio/interier/luxury.js" /* webpackChunkName: "component---src-pages-portfolio-interier-luxury-js" */),
  "component---src-pages-portfolio-interier-mirrors-js": () => import("./../../../src/pages/portfolio/interier/mirrors.js" /* webpackChunkName: "component---src-pages-portfolio-interier-mirrors-js" */),
  "component---src-pages-portfolio-interier-mogilev-apart-js": () => import("./../../../src/pages/portfolio/interier/mogilev-apart.js" /* webpackChunkName: "component---src-pages-portfolio-interier-mogilev-apart-js" */),
  "component---src-pages-portfolio-interier-moskvina-js": () => import("./../../../src/pages/portfolio/interier/moskvina.js" /* webpackChunkName: "component---src-pages-portfolio-interier-moskvina-js" */),
  "component---src-pages-portfolio-interier-nezavisimosti-js": () => import("./../../../src/pages/portfolio/interier/nezavisimosti.js" /* webpackChunkName: "component---src-pages-portfolio-interier-nezavisimosti-js" */),
  "component---src-pages-portfolio-interier-panfilova-js": () => import("./../../../src/pages/portfolio/interier/panfilova.js" /* webpackChunkName: "component---src-pages-portfolio-interier-panfilova-js" */),
  "component---src-pages-portfolio-interier-podmoskovye-interier-js": () => import("./../../../src/pages/portfolio/interier/podmoskovye-interier.js" /* webpackChunkName: "component---src-pages-portfolio-interier-podmoskovye-interier-js" */),
  "component---src-pages-portfolio-interier-prestizhino-js": () => import("./../../../src/pages/portfolio/interier/prestizhino.js" /* webpackChunkName: "component---src-pages-portfolio-interier-prestizhino-js" */),
  "component---src-pages-portfolio-interier-rekonstrukciya-dom-js": () => import("./../../../src/pages/portfolio/interier/rekonstrukciya-dom.js" /* webpackChunkName: "component---src-pages-portfolio-interier-rekonstrukciya-dom-js" */),
  "component---src-pages-portfolio-interier-simple-house-js": () => import("./../../../src/pages/portfolio/interier/simple-house.js" /* webpackChunkName: "component---src-pages-portfolio-interier-simple-house-js" */),
  "component---src-pages-portfolio-interier-sochi-js": () => import("./../../../src/pages/portfolio/interier/sochi.js" /* webpackChunkName: "component---src-pages-portfolio-interier-sochi-js" */),
  "component---src-pages-portfolio-interier-storojevskaya-js": () => import("./../../../src/pages/portfolio/interier/storojevskaya.js" /* webpackChunkName: "component---src-pages-portfolio-interier-storojevskaya-js" */),
  "component---src-pages-portfolio-interier-sunny-js": () => import("./../../../src/pages/portfolio/interier/sunny.js" /* webpackChunkName: "component---src-pages-portfolio-interier-sunny-js" */),
  "component---src-pages-portfolio-interier-tarasovo-interier-js": () => import("./../../../src/pages/portfolio/interier/tarasovo-interier.js" /* webpackChunkName: "component---src-pages-portfolio-interier-tarasovo-interier-js" */),
  "component---src-pages-portfolio-interier-troickoe-js": () => import("./../../../src/pages/portfolio/interier/troickoe.js" /* webpackChunkName: "component---src-pages-portfolio-interier-troickoe-js" */),
  "component---src-pages-portfolio-interier-vostochnaya-js": () => import("./../../../src/pages/portfolio/interier/vostochnaya.js" /* webpackChunkName: "component---src-pages-portfolio-interier-vostochnaya-js" */),
  "component---src-pages-portfolio-interier-zelenaya-gavan-js": () => import("./../../../src/pages/portfolio/interier/zelenaya-gavan.js" /* webpackChunkName: "component---src-pages-portfolio-interier-zelenaya-gavan-js" */),
  "component---src-pages-portfolio-interier-zelenyi-js": () => import("./../../../src/pages/portfolio/interier/zelenyi.js" /* webpackChunkName: "component---src-pages-portfolio-interier-zelenyi-js" */),
  "component---src-pages-portfolio-interier-zhodino-js": () => import("./../../../src/pages/portfolio/interier/zhodino.js" /* webpackChunkName: "component---src-pages-portfolio-interier-zhodino-js" */),
  "component---src-pages-portfolio-office-js": () => import("./../../../src/pages/portfolio/office.js" /* webpackChunkName: "component---src-pages-portfolio-office-js" */),
  "component---src-pages-portfolio-public-objects-js": () => import("./../../../src/pages/portfolio/public-objects.js" /* webpackChunkName: "component---src-pages-portfolio-public-objects-js" */),
  "component---src-pages-portfolio-realize-js": () => import("./../../../src/pages/portfolio/realize.js" /* webpackChunkName: "component---src-pages-portfolio-realize-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-uslugi-arxitekturnoe-proektirovanie-js": () => import("./../../../src/pages/uslugi/arxitekturnoe-proektirovanie.js" /* webpackChunkName: "component---src-pages-uslugi-arxitekturnoe-proektirovanie-js" */),
  "component---src-pages-uslugi-avtorskoe-soprovozhdenie-js": () => import("./../../../src/pages/uslugi/avtorskoe-soprovozhdenie.js" /* webpackChunkName: "component---src-pages-uslugi-avtorskoe-soprovozhdenie-js" */),
  "component---src-pages-uslugi-dizajn-interera-domov-js": () => import("./../../../src/pages/uslugi/dizajn-interera-domov.js" /* webpackChunkName: "component---src-pages-uslugi-dizajn-interera-domov-js" */),
  "component---src-pages-uslugi-dizajn-interera-js": () => import("./../../../src/pages/uslugi/dizajn-interera.js" /* webpackChunkName: "component---src-pages-uslugi-dizajn-interera-js" */),
  "component---src-pages-uslugi-dizajn-interera-ofisov-js": () => import("./../../../src/pages/uslugi/dizajn-interera-ofisov.js" /* webpackChunkName: "component---src-pages-uslugi-dizajn-interera-ofisov-js" */),
  "component---src-pages-uslugi-komplektaciya-obekta-js": () => import("./../../../src/pages/uslugi/komplektaciya-obekta.js" /* webpackChunkName: "component---src-pages-uslugi-komplektaciya-obekta-js" */),
  "component---src-pages-uslugi-soglasovanie-js": () => import("./../../../src/pages/uslugi/soglasovanie.js" /* webpackChunkName: "component---src-pages-uslugi-soglasovanie-js" */),
  "component---src-pages-uslugi-stroitelstvo-i-rekonstrukciya-js": () => import("./../../../src/pages/uslugi/stroitelstvo-i-rekonstrukciya.js" /* webpackChunkName: "component---src-pages-uslugi-stroitelstvo-i-rekonstrukciya-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

